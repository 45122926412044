.pageNotFound {
  height: 100vh;
  background-color: #f5f6fa;
}
.pageNotFound_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 0 15px;
}
.pageNotFound_iconImg {
  width: 70px;
  margin-bottom: 10px;
}
.pageNotFound h1 {
  font-size: 160px;
  font-weight: 700;
  color: #364a63;
  margin-bottom: 0;
  line-height: normal;
}
.pageNotFound h2 {
  font-size: 32px;
  margin-bottom: 15px;
  color: #364a63;
  font-weight: 700;
}
.pageNotFound p {
  font-size: 18px;
  color: #526484;
  font-weight: 500;
  margin-bottom: 20px;
}
.pageNotFound a {
  height: 36px;
  background-color: #f47721;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.pageNotFound a:hover {
  background-color: #cf651c;
}

.pageNotFound .pageNotFound_link,
.pageNotFound .pageNotFound_link:hover {
  color: #cf651c;
  padding: 0;
  font-size: 15px;
  background-color: transparent;
  height: auto;
}
@media (max-width: 1399px) {
  .pageNotFound h1 {
    font-size: 130px;
  }
  .pageNotFound h2 {
    font-size: 28px;
  }
}
@media (max-width: 1199px) {
  .pageNotFound h1 {
    font-size: 100px;
  }
  .pageNotFound h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .pageNotFound h1 {
    font-size: 80px;
  }
  .pageNotFound h2 {
    font-size: 22px;
  }
  .pageNotFound p {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .pageNotFound h1 {
    font-size: 65px;
  }
  .pageNotFound h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .pageNotFound p {
    font-size: 14px;
  }
}
/* h1{
        font-size: 160px;
        font-weight: 700;
    }
    h2{
        font-size: 32px;margin-bottom: 15px;
    }
    p{font-size: 18px;}
    
    @media(max-width: 1399px){
        h1{
            font-size: 130px;
        }
        h2{font-size: 28px;}
    }
    @media(max-width: 1199px){
        h1{
            font-size: 100px;
        }
        h2{font-size: 25px;}
    }
    @media(max-width: 767px){
        
        h1{
            font-size: 70px;
        }
        h2{font-size: 22px;}
        p{font-size: 16px;}
    }

} */
